import React from 'react';
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import NotFoundPage from "../pages/404";

export default function BlogPage({data}) {

  return (
    <>
    <NotFoundPage/>
    </>
  )
}

export const blogQuery = graphql`
query BlogQuery {
  allPrismicBlogPost(sort: {fields: data___publishing_date, order: DESC}) {
    edges {
      node {
        id
        data {
          publishing_date
          title {
            text
          }
          cover_image {
            url
          }
          content {
            text
          }
        }
        url
      }
    }
  }
}
`